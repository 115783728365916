.faq-row-wrapper {
    padding-left: 90px !important;
    padding-right: 90px !important;
}

.faq-title h2 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    margin-top: 50px !important;
    margin-bottom: 10px !important;
}

.faq-row {
    
}

.row-title {
    align-items: center !important;
    
}

.row-title-text {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
}

.row-content {
}

.row-content-text {
    text-align: left;
    font-size: 1.1rem !important;
}