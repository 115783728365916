.hero {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 500px;
    color: white,
}

.card-main {
    cursor: pointer;
}

.card-title-first {
    font-size: 0.9rem;
    font-weight: 900;
    text-align: left;
    color: #008ec2;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-title-second{
    font-weight: 900; 
    text-align: left;
}

.card-content {
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
}

.title {
    font-size: 2.5rem;
    font-weight: 900;
}

.date {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}

.desc {
    font-size: 0.9rem;
    text-align: center;
}

.pre-next {
    background-size: cover;
    background-repeat: no-repeat;
    height: 18rem;
    color: white;
    cursor: pointer;
}

.pre-next-title-container-left {
    text-align: left;
    margin-left: 50px;
}

.pre-next-title-container-right {
    text-align: right;
    margin-left: 50px;
}

.pre-next-label{
    font-size: 1rem;
    font-weight: 700;
}

.pre-next-title{
    font-size: 1.5rem;
    font-weight: 700;
}