.footer-container {
    background-color: #343a40;
    color: gray;
    font-size: 0.8rem;
}

.footer-link {
    
    text-decoration: none;
    color: white;
}

@media (min-width: 576px) {  
    .footer-container {
        margin-top: 0px;
    }
}