/** {
  font-family: 'Montserrat';
}*/

*:not(html) {
  -webkit-transform: translate3d(0, 0, 0);
}

html,
body {
  height: 100%;
  /*max-width: 100%;
  overflow: hidden !important;*/
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  position: relative;
}

.icon {
  height: 1rem;
  width: 1rem;
  position: absolute;
  box-sizing: border-box;
  top: 35%;
  /*left: 10px;*/
  transform: translateY(-40%);
}

.input {
  height: 50px;
  box-sizing: border-box;
  padding-left: 2rem;
}

input {
  outline: "none";
  border-width: 0px;
  border-radius: 5px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px;
  font-size: 1rem;
  border-radius: 0px;
  outline: none;
  padding-left: 1.5rem;
}

.location-search-input-no-shadow,
.location-search-input-no-shadow:focus {
  box-shadow: none;
}

.autocomplete-dropdown-container {
  /*border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
  position: absolute;
  width: 100%;
  z-index: 1000;
  border-radius: 0 0 2px 2px;
  text-align: left;
}

input::placeholder {
  color: #b5b5b5;
  font-size: 0.7rem;
}

.input-wrapper {
  display: flex;
  align-items: stretch;
}

.input-left {
  display: flex;
  background-color: #fafafa;
  font-size: 0.7rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.input-right {
  flex: 2;
}

.listing-body input::placeholder {
  font-size: 0.8rem;
}

/*.fp-overflow {
  overflow: hidden;
  height: 100%;
}*/

@media (min-width: 576px) {
  input::placeholder {
    font-size: 0.9rem;
  }
  .location-search-input,
  .location-search-input:focus,
  .location-search-input:active {
    padding-left: 2.5rem;
  }
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    top: 50%;
   /*left: 10px;*/
    transform: translateY(-50%);
    left: 5px
  }
}
