.attach-image-wrapper .trash-btn {
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
}

.attach-image-wrapper:hover .trash-btn {
    
    transform: scale(1.2);
}

.attach-image-menu-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 40px;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1000;
    background-color: black;
    color: white;
}

.attach-image-wrapper:hover .attach-image-menu-wrapper {
    opacity: 1;
}

@media (min-width: 576px) {
    .react-multi-carousel-item {
        max-width: 30vw;
    }
    
    .attach-image-wrapper .attach-image-menu-wrapper {
        opacity: 0.3;
    }
}