.detail-container,.profile-container {
    position: relative;
    z-index: 900;
    background-color: var(--detail-bg-color);
    color: white;
}

.profile-container {
    background-color: var(--profile-bg-color) !important;
}

.toast {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.listing-hero {
    background-color: var(--home-bg-color);
    background: url(../../assets/images/listing-banner.jpg);
    background-size: contain !important;
    /*background-position-y: -100px;*/
    height: 300px;
    color: white;
    
}

.listing-body {
    background-color: var(--home-bg-color);
    background: url(../../assets/images/listing-body.jpg);
    background-size: cover !important;
    display: flex;
    justify-content: center;
}

.listing-body-top {
    padding: 0rem 2rem;
    justify-content: center;
    z-index: 1000;
}

.listing-body-main {
    padding: 0rem 1rem;
}



.detail-hero {
    top: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 400px;
    color: white;
}

.detail-title {
    font-size: 1.5rem;
    font-weight: 800;
    left: 30px;
    bottom: 30px;
    position: absolute;
    text-align: left;
    max-width: 80%;
}

.listing-search-form {
    background-color: white;
    padding: 20px 40px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.listing-search-form input {
    height: 35px !important;
    background-color: transparent !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 0px;
    box-shadow: none;
    padding-left: 1rem !important;
    margin-left: 0px !important;
    font-size: 0.8rem !important;
}

.listing-search-form input::placeholder {
    color: #999;
}

.listing-search-form input:focus {
    box-shadow: none;
}

.listing-search-form select {
    height: 35px;
    background-color: white !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 0px;
    font-size: 0.8rem;
}

.listing-search-form select:focus {
    box-shadow: none;
}

.listing-search-form-btn {
    border: 1px solid #D9D9D9;
    font-size: 0.8rem;
}

.listing-search-form-clear-btn {
    background-color: white;
    color: #595959;
}

.listing-search-form-sort-btn, .listing-search-form-apply-btn, .listing-search-form-apply-btn:hover  {
    background-color: var(--primary-color) !important;
    border: none !important;
    box-shadow: none !important;
}

.listing-search-form-sort-btn {
    background-color: white !important;
    color: #2C2C2C !important;
}

.listing-search-form-apply-btn.active {
    background-color: white !important;
    color: #2C2C2C !important;
}

.listing-search-form .row:first-of-type {
    position: relative;
    z-index: 1000;
}

.contact-title {
    font-size: 1.2rem;
    font-weight: 800;
    text-align: left;
}

/*.gallary-title, .gallary-slider {
    margin-left: 40px;
}*/

.detail-field-lable {
    font-size: 0.9rem;
    text-align: left;
}

.detail-field-value {
    font-weight: 900;
    word-wrap: break-word;
    text-align: left;
}

.detail-field-value a {
    color: white;
}

.contact-form {
    background-color: white;
    text-align: left;
    border-radius: 10px;
    margin-top: 50px;
    height: 550px;
}

.form-title {
    color: black;
    font-size: 0.9rem;
    
}

.submit {
    background-color: var(--primary-color);
}

.edit-listing-title {
    font-weight: 900;
    text-align: left;
    margin-bottom: 50px;
    margin-left: 2rem;
    margin-right: 2rem;
}

.edit-listing-label {
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.edit-listing-note {
    font-size: 0.7rem;
}



.search-tags {
    background-color: #008ec2;
    padding: 5px 10px;
    margin: 5px;
    font-size: 0.6rem;
    font-weight: 600;
    color: white;
    border-radius: 8px;
}

.search-tags .fa-sort {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.search-tags .fa-close {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    cursor: pointer;
}

.search-sort {
    border: solid 1px #008ec2;
    color: #008ec2;
}

.search-form-action-mobile {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.search-form-row {
    display: flex;
}

.dropdown-menu {
    border-radius: 0.5rem;
}

.sort-dropdown-menu {
    display: flex;
    gap: 20px;
    align-items: center;
}

input[type="radio"] {
    border-color: var(--primary-color);
}

input[type="radio"]:checked {
    background-color: var(--primary-color);
}

input[type="radio"]:disabled {
    opacity: 1;
}

@media (min-width: 576px) {  
    .listing-body-top {
        padding: 0rem 8rem;
    }
    .detail-title {
        font-size: 2.5rem;
        font-weight: 900;
        left: 90px;
        bottom: 90px;
        position: absolute;
        text-align: left;
    }

    .contact-title {
        font-size: 2.0rem;
        font-weight: 800;
        text-align: left;
    }

    /*.gallary-title, .gallary-slider {
        margin-left: 80px;
    }*/

    .contact-form {
        margin-top: 0px;
    }

    .search-form-action-mobile {
        display: none;
    }

    .search-form-row {
        gap: 15px;
    }

    .search-tag-wrapper {
        display: flex;
    }

    .search-tags {
        font-size: 0.7rem;
        font-weight: 900;
    }

    .edit-listing-title {
        margin-left: 7rem;
        margin-right: 7rem;
    }

    .edit-listing-label {
        margin-left: 7rem;
        margin-right: 7rem;
    }
}