.fc-card-main {
    text-align: left; 
    color: black;
    cursor: pointer;
    height: 400px;
}

.fc-card-image {
    background-size: cover;
    background-position-y: -50;
    background-position: center center;
}

.fc-card-title-link {
    text-decoration: none;
    color: black;
    font-weight: 900;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.fc-card-text-link {
    text-decoration: none;
    color: black;
    font-size: 0.9rem;
}

.fc-card-email-lable
{
    display: flex;
    font-weight: 700;
    font-size: 0.9rem;
                            
}

.fc-card-email-link {
    text-decoration: none;
    font-size: 0.9rem;
    margin-left: 3px;
    color: black;
    word-break: break-all;
}

.carousel-row {
    margin: 0px 0px 0px 0px;
}

.carousel-buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 20px 0.1rem 20px 0.1rem;
    margin-top: 180px;
}

.react-multi-carousel-dot button {
    background-color: white;
    border-color: #1D6F93;
    
} 

.react-multi-carousel-dot--active button {
    background-color: #1D6F93;
}

/*.carousel-row {
    margin: 5px;
}*/

@media (min-width: 576px) {  

    .fc-card-title-link {
        overflow: visible;
        text-overflow: ellipsis;
        display: block;
        -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4; 
        -webkit-box-orient: vertical;
    }
    .carousel-row {
        margin: 20px 100px 0px 100px;
    }

    .carousel-buttons {
        padding: 20px 4rem 20px 4rem;
        min-width: 120%;
    }

    .react-multi-carousel-dot button {
        width: 1rem;
        height: 1rem;
    }
}
