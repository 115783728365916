.main-header {
    width: 100%;
    z-index: 1000;
    /*background-color: var(--home-bg-color);*/
}
.mobile-menu-bg {
    padding-right: 20px;
}

.navbar-collapse.collapse.show {
    /*background-color: var(--home-bg-color);*/
}

div:has(.navbar-collapse.collapse.show) {
    /*background-color: var(--home-bg-color);*/
}

div:has(.navbar-collapse.collapse).notHome {
    background-color: transparent;
}

.profile-button-icon {
    font-size: 1.5rem !important;
    color: var(--primary-color);
}

.dropdown-toggle::after {
    margin-left: 8px !important;
    color: var(--primary-color);
}

.dropdown-menu {
    background-color: var(--home-bg-color);
    opacity: 0.7;
}

.dropdown-item:hover {
    background-color: var(--primary-color);
}

.dropdown-menu .dropdown-item {
    color: white !important;
}

@media (min-width: 576px) {  
    .main-header, .navbar-nav {
        background-color: transparent;
    }
    .dropdown-menu {
        margin-left: -100px;
        background-color: var(--home-bg-color);
        opacity: 0.7;
    }
    .navbar-nav {
        /*background-color: var(--home-bg-color);*/
        /*width: 110vw !important;*/
        margin-right: 3rem;
    }
}