:root {
    --primary-color:#008ec2;
}

.list-tile-row {
    display: flex;
    align-items: center;
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    transition: transform .2s;
    cursor: pointer;
}

.list-tile-row:hover {
    transform: scale(1.05);
}

.tile-datetimebox, .tile-content {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    margin: 0rem 0.5rem 0rem 0rem;
}

.tile-datetimebox {
    border-radius: 10px;
}

.tile-datetimebox div:first-of-type {
    font-size: 1rem;
}

.tile-datetimebox div:last-of-type {
    font-size: 1.5rem;
    font-weight: 700;
}

.tile-content {
    text-align: left;
}

.tile-content div:first-of-type {
    font-weight: 600;
}

.tile-calendar button{
    background-color: transparent;
    color: #000;
    font-size: 0.9rem;
    border: none;
}

.tile-calendar button:hover {
    background-color: var(--primary-color)
}

.tile-calendar button::before {
    content: "+";
    margin-right: 10px;
}

@media (max-width: 576px) {
    .tile-datetimebox, .tile-content {
        padding: 10px 5px;
        margin: 0rem 0.5rem 0rem 0rem;
    }
    .tile-datetimebox div:first-of-type {
        font-size: 0.8rem;
    }
    
    .tile-datetimebox div:last-of-type {
        font-size: 1.2rem;
        font-weight: 700;
    }
    .tile-content {
        text-align: center;
    }
    
    .tile-content div:first-of-type {
        font-weight: 500;
    }
    .tile-calendar button{
        background-color: #000;
        color: #FFF;
    }
}