@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold");

.login-hero {
  background: url(../assets/images/login-banner.jpg);
  background-size: cover !important;
  /*background-position-y: -100px;*/
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-hero div {
  font-family: "Inter";
}

.login-form {
  width: 23rem;
  padding: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 368px;
}

.login-form-text {
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.login-form-password {
    margin-bottom: 0px;
}

.login-form input[type="text"],
input[type="password"] {
  padding-left: 10px;
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d8dadc;
  
}

.login-form input[type="text"]:not(.login-form-password) {
    margin-bottom: 20px;
}

input[type="password"]:not(.login-form-password) {
    margin-bottom: 20px;
}

a {
    font-family: "Inter";
    color: black;
    text-decoration: none;
    cursor: pointer;
}

a.blue-link {
  color: blue
}

.login-button {
    background-color: black !important;
    border: none !important;
}

@media (min-width: 768px) {
  .login-hero {
    justify-content: end;
    position: relative;
  }

  .login-form {
    width: 26rem;
    position: absolute;
    right: -184px;
  }
}
