@import url('https://fonts.googleapis.com/css?family=Inter:regular,bold');


:root {
    --primary-color:#008ec2;
    --home-bg-color: #040809;
    --profile-bg-color: #353535;
    --detail-bg-color: #909090;
    --icon-color: #1D6F93;
}

* {
    font-family: 'Inter';
    
}

.home-bg {
    background-color: var(--home-bg-color);
    /*height: 100vh;*/
    
}

.full-screen-height {
    height: 100vh;
    /*overflow: hidden;*/
}

.events-section-noscroll {
    overflow: hidden;
}

.events-section-scroll {
    overflow: scroll !important;
}

.main-hero {
    background-color: var(--home-bg-color);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../../assets/images/banner.png);
    background-size: cover !important;
    /*background-position-y: -100px;*/
    background-position: top;
    background-repeat: no-repeat;
    
}

.navbar-main {
    background-color: var(--home-bg-color);
    padding-top: 20px;
}

.navbar-menu {
    font-size: 1.1rem;
    font-weight: 500;
    color: white !important;
    margin: 1rem;
}

.home-title-main {
    font-size: 2rem;
    font-weight: 800;
    color: #96DFFF;
    line-height: 1rem;
}

.home-title-p {
    font-weight: 400;
    text-align: left;
}

.home-title-subtitle {
    font-size: 2.5rem;
    font-weight: 800;
    color: #96DFFF;
}

.home-search-title {
    font-size: 1.4rem;
    font-weight: 800;
    color: #96DFFF;
    margin-top: 50px;
    margin-bottom: 20px;
}

.explore-button {
    background-color: var(--primary-color);
    color: white;
    margin-top: 50;
    border: none;
    float: left;
}

/*.search-section {
    position: relative;
    margin-top: 100,
}*/

.fc-hero {
    background-color: var(--home-bg-color);
    background-image: url(../../assets/images/fc_banner.png);
    background-size: cover !important;
    /*height: 100vh;*/
    /*position: relative;
    margin-top: 50px;*/
}

.fc-icon {
    color: var(--icon-color)
}

.fc-curvy-line {
    position: absolute;
    bottom: -1px;
    right: -1px;

}

.search-menu, .search-inactive {
    width: 90px;
    /*height: 150px;*/
    height: 12vh; 
    text-align: left;
    vertical-align: middle;
    /*padding-left: 4rem;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid var(--primary-color);
}

.search-menu-title {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--primary-color);
    text-align: center;
}

.search-active {
    background-color: var(--primary-color),
}

.hero-left {
    text-align: left;
    line-height: 1;
}

.events-hero {
    background-color: #040809;
    background: linear-gradient(to bottom, rgba(4, 8, 9, 0.8), rgba(4, 8, 9, 0)), url('../../assets/images/ob_banner.png');
    background-size: cover !important;
    /*padding-top: 20px;*/
  }

.image {
    position: relative;
    line-height: 2em;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.row>* {
    padding-left: 0px;
    padding-right: 0px;
}

.search-title {
    font-size: 1.5rem;
    font-weight: 800;
    color: #96DFFF;
    margin-top: 80px;
    margin-bottom: 20px;
}

.dropdown-toggle::after {
    margin-left: 25px;
}

.white-title {
    color: white;
}

.left-title {
    text-align: left;
    margin-left: 3.5rem;
    /*margin-left: 5rem;*/
}

.react-multi-carousel-list {
    padding-bottom: 30px;
}

.react-multi-carousel-track {
    flex: 1
}

@media (min-width: 576px) {  
    
    .home-title-main {
        font-size: 2.5rem;
        position: relative;
        z-index: 1;
    }

    .home-title-subtitle {
        font-size: 3.5rem;
    }
    
    .home-search-title {
        font-size: 2.2rem;
        position: relative;
        z-index: 1;
    }

    .search-title {
        font-size: 2.5rem;
        margin-top: 30px;
    }

    .search-menu, .search-inactive {
        width: 250px;
        height: 70px;
    }

    .search-menu-title {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .search-menu:hover {
        background-color: var(--primary-color);
        transition: 1s;
        cursor: pointer;
    }
    
    .search-menu:hover div:nth-child(1) {
        color: white !important;
    }

    .left-title {
        padding-left: 90px;
    }

    .searchSection {
        margin-top: 50px;
    }

}