.search-input {
  background-color: white;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-btn {
  background-color: #008ec2;
  color: white;
  border: none;
  margin-left: -5px;
  height: 40px !important;
  width: 100px !important;
  font-size: 0.8rem;
}

.search-btn-icon {
    padding: 3px 10px;
    font-size: 15px;
}

@media (min-width: 576px) {
  .search-input {
    margin-left: -1px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .search-btn {
    height: 100% !important;
    width: 100% !important;
    font-size: 1rem;
  }
  .search-btn-icon {
    padding: 1px 10px;
    font-size: 20px;
}
}
