.cl-card-main {
    text-align: left;
    cursor: pointer;
    height: 400px;
}

.cl-card-image {
    object-fit: contain;
    background-position-y: -50;
    background-position: center center;
}

.cl-card-title-link {
    text-decoration: none;
    font-weight: 900;
    color: #008ec2;
}

.cl-card-phone-link {
    text-decoration: none;
    font-size: 0.9rem;
    color: #008ec2;
}

.cl-card-email-lable
{
    display: flex;
    font-weight: 700;
    font-size: 0.9rem;
                            
}

.cl-card-email-link {
    text-decoration: none;
    font-size: 0.9rem;
    margin-left: 3px;
    color: #008ec2;
    word-break: break-all;
}

.page-item .page-link {
    color: black;
    border-radius: 3px;
}

.page-item.active .page-link {
    background-color: white;
    border: 1px solid #00708F;
    color: #00708F;
}

.pagination li {
    margin: 5px;
}

/*.chevron-pre-next {
    display: none;
}*/

.chevron-pre-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    
}

.chevron-pre-next:first-child {
    margin-right: 10px;
}

.chevron-pre-next:last-child {
    margin-left: 10px;
}

@media (min-width: 576px) {
    .chevron-pre-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
    }
}